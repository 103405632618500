@media only screen and (max-width: 300px) {
    .overarching {
        height: 412vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(132, 255, 132);

    }

    .aboutUs_AboutUs {
        position: relative;
        background-image: url(../MEDIA/WhoWeAreTaxes.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        /* z-index: 1; */
        height: 50vh;
        display: flex;
        align-items: center;

    }

    .aboutUs_AboutUs::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(85, 188, 0, 0.328);

        /* Red filter with 50% opacity */
        /* z-index: 2; */
        /* Adjust the z-index to place it below the content */
    }

    .aboutUs_fullpage {
        height: 360vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(132, 255, 132);

    }

    .bioCard {
        height: 80vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }

    .bioCard_banner {
        height: 20vh;
        width: 94.8vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bioCard_image {
        height: 20vh;
        width: 45vw;
        border-top-right-radius: 10%;
    }

    .bioCard_name {
        width: 35vw;
        height: 60vh;
        font-size: 30px;
        margin: 10px;
        text-align: right;
    }

    .bioCard_mid {
        height: 90vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }

    .bioCard_mid_banner {
        height: 20vh;
        width: 95vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bioCard_mid_image {
        height: 20vh;
        width: 45vw;
        border-top-right-radius: 10%;

    }

    .bioCard_mid_image_bighead {
        height: 20vh;
        width: 38vw;
        border-top-right-radius: 10%;
    }

    .bioCard_mid_name {
        width: 35vw;
        height: 60vh;
        font-size: 25px;
        margin: 10px;
        text-align: right;

    }

    .bio_drali {
        font-size: 8.2px;
        margin: 15px;
    }

    .bio_zarina {
        font-size: 10.6px;
        margin: 15px;
    }

    .bio_claudia {
        font-size: 12px;
        margin: 15px;
    }

    .bio_bighead {
        font-size: 11.5px;
        margin: 15px;
    }
}

@media only screen and (min-width: 301px) and (max-width:600px) {
    .overarching {
        height: 412vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(132, 255, 132);

    }

    .aboutUs_AboutUs {
        position: relative;
        background-image: url(../MEDIA/WhoWeAreTaxes.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        /* z-index: 1; */
        height: 50vh;
        display: flex;
        align-items: center;

    }

    .aboutUs_AboutUs::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(85, 188, 0, 0.328);

        /* Red filter with 50% opacity */
        /* z-index: 2; */
        /* Adjust the z-index to place it below the content */
    }

    .aboutUs_fullpage {
        height: 360vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(132, 255, 132);

    }

    .bioCard {
        height: 80vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }

    .bioCard_banner {
        height: 20vh;
        width: 94.8vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bioCard_image {
        height: 20vh;
        width: 45vw;
        border-top-right-radius: 10%;
    }

    .bioCard_name {
        width: 35vw;
        height: 60vh;
        font-size: 20px;
        margin: 10px;
        text-align: right;
    }

    .bioCard_mid {
        height: 90vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;

    }

    .bioCard_mid_banner {
        height: 20vh;
        width: 95vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bioCard_mid_image {
        height: 20vh;
        width: 45vw;
        border-top-right-radius: 10%;

    }

    .bioCard_mid_image_bighead {
        height: 20vh;
        width: 38vw;
        border-top-right-radius: 10%;
    }

    .bioCard_mid_name {
        width: 35vw;
        height: 60vh;
        font-size: 25px;
        margin: 10px;
        text-align: right;
    }

    .bio_drali {
        font-size: 10px;
        margin: 15px;
    }

    .bio_zarina {
        font-size: 12px;
        margin: 15px;
    }

    .bio_claudia {
        font-size: 14px;
        margin: 15px;
    }

    .bio_bighead {
        font-size: 13px;
        margin: 15px;
    }
}

@media only screen and (min-width: 601px) and (max-width:900px) {
    .overarching {
        height: 412vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(132, 255, 132);

    }

    .aboutUs_AboutUs {
        position: relative;
        background-image: url(../MEDIA/WhoWeAreTaxes.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        /* z-index: 1; */
        height: 50vh;
        display: flex;
        align-items: center;

    }

    .aboutUs_AboutUs::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(85, 188, 0, 0.328);

        /* Red filter with 50% opacity */
        /* z-index: 2; */
        /* Adjust the z-index to place it below the content */
    }

    .aboutUs_fullpage {
        height: 360vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(132, 255, 132);

    }

    .bioCard {
        height: 80vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;


    }

    .bioCard_banner {
        height: 40vh;
        width: 94.8vw;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .bioCard_image {
        height: 40vh;
        width: 45vw;
        border-top-left-radius: 10%;
    }

    .bioCard_name {
        width: 50vw;
        font-size: 36px;
        margin: 10px;
        text-align: start;
    }

    .bioCard_mid {
        height: 90vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;

    }

    .bioCard_mid_banner {
        height: 40vh;
        width: 95vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .bioCard_mid_image {
        height: 40vh;
        width: 45vw;
        border-top-left-radius: 10%;

    }

    .bioCard_mid_image_bighead {
        height: 40vh;
        width: 45vw;
        border-top-left-radius: 10%;
    }

    .bioCard_mid_name {
        width: 60vw;
        font-size: 35px;
        margin: 10px;
        text-align: center;
        text-align: start;
    }

    .bio_drali {
        font-size: 20px;
        margin: 15px;
    }

    .bio_zarina {
        font-size: 25px;
        margin: 15px;
    }

    .bio_claudia {
        font-size: 28px;
        margin: 15px;
    }

    .bio_bighead {
        font-size: 28px;
        margin: 15px;
    }
}

@media only screen and (min-width: 901px) and (max-width:1200px) {
    .overarching {
        height: 412vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(132, 255, 132);

    }

    .aboutUs_AboutUs {
        position: relative;
        background-image: url(../MEDIA/WhoWeAreTaxes.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        /* z-index: 1; */
        height: 50vh;
        display: flex;
        align-items: center;

    }

    .aboutUs_AboutUs::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(85, 188, 0, 0.328);

        /* Red filter with 50% opacity */
        /* z-index: 2; */
        /* Adjust the z-index to place it below the content */
    }

    .aboutUs_fullpage {
        height: 360vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(132, 255, 132);

    }

    .bioCard {
        height: 80vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;


    }

    .bioCard_banner {
        height: 50vh;
        width: 94.8vw;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .bioCard_image {
        height: 50vh;
        width: 45vw;
        border-top-left-radius: 10%;
    }

    .bioCard_name {
        width: 50vw;
        font-size: 36px;
        margin: 10px;
        text-align: start;
    }

    .bioCard_mid {
        height: 90vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;

    }

    .bioCard_mid_banner {
        height: 50vh;
        width: 95vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .bioCard_mid_image {
        height: 50vh;
        width: 45vw;
        border-top-left-radius: 10%;

    }

    .bioCard_mid_image_bighead {
        height: 50vh;
        width: 45vw;
        border-top-left-radius: 10%;
    }

    .bioCard_mid_name {
        width: 60vw;
        font-size: 35px;
        margin: 10px;
        text-align: center;
        text-align: start;
    }

    .bio_drali {
        font-size: 14px;
        margin: 15px;
    }

    .bio_zarina {
        font-size: 18px;
        margin: 15px;
    }

    .bio_claudia {
        font-size: 20px;
        margin: 15px;
    }

    .bio_bighead {
        font-size: 20px;
        margin: 15px;
    }
}

@media only screen and (min-width: 1201px) {
    .overarching {
        height: 412vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(132, 255, 132);

    }

    .aboutUs_AboutUs {
        position: relative;
        background-image: url(../MEDIA/WhoWeAreTaxes.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        /* z-index: 1; */
        height: 50vh;
        display: flex;
        align-items: center;

    }

    .aboutUs_AboutUs::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(85, 188, 0, 0.328);

        /* Red filter with 50% opacity */
        /* z-index: 2; */
        /* Adjust the z-index to place it below the content */
    }

    .aboutUs_fullpage {
        height: 360vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(132, 255, 132);

    }

    .bioCard {
        height: 80vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .bioCard_banner {
        height: 50vh;
        width: 94.8vw;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .bioCard_image {
        height: 55vh;
        width: 35vw;
        border-top-left-radius: 10%;
    }

    .bioCard_name {
        width: 50vw;
        font-size: 36px;
        margin: 20px;
        text-align: start;
    }

    .bioCard_mid {
        height: 70vh;
        width: 95vw;
        background-color: rgba(255, 228, 196, 0.874);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .bioCard_mid_banner {
        height: 50vh;
        width: 95vw;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .bioCard_mid_image {
        height: 60vh;
        width: 35vw;
        border-top-left-radius: 10%;

    }

    .bioCard_mid_image_bighead {
        height: 50vh;
        width: 35vw;
        border-top-left-radius: 10%;
    }

    .bioCard_mid_name {
        width: 50vw;
        font-size: 35px;
        margin: 20px;
        text-align: center;
        text-align: start;
    }

    .bio_drali {
        font-size: 20px;
        margin: 15px;
    }


    .bio_zarina {
        font-size: 25px;
        margin: 15px;
    }

    .bio_claudia {
        font-size: 28px;
        margin: 15px;
    }

    .bio_bighead {
        font-size: 28px;
        margin: 15px;
    }
}