@media only screen and (max-width: 600px) {


    .carousel-container {
        height: 35vh;
        width: 100%;
        overflow: hidden;
    }

    .carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .active {
        transform: translateX(-100%);
    }

    .passed-images {
        height: 35vh;
        width: 100vw
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .carousel-container {
        height: 40vh;
        width: 100%;
        overflow: hidden;
    }

    .carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .active {
        transform: translateX(-100%);
    }

    .passed-images {
        height: 50vh;
        width: 100vw
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .carousel-container {
        height: 40vh;
        width: 100%;
        overflow: hidden;
    }

    .carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .active {
        transform: translateX(-100%);
    }

    .passed-images {
        height: 50vh;
        width: 100vw
    }
}

@media only screen and (min-width: 1201px) {
    .carousel-container {
        height: 80vh;
        width: 100%;
        overflow: hidden;
    }

    .carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .slide {
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
    }

    .active {
        transform: translateX(-100%);
    }

    .passed-images {
        height: 70vh;
        width: 100vw;
    }
}