@media only screen and (max-width: 300px) {


    .basicsliderserviceslist_carousel-container {
        height: 30vh;
        width: 100vw;
        overflow: hidden;
    }

    .basicsliderserviceslist_carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .basicsliderserviceslist_slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .basicsliderserviceslist_active {
        transform: translateX(-100%);
    }

    .basicsliderserviceslist_passed-images {
        height: 30vh;
        width: 100vw
    }
}

@media only screen and (min-width: 301px) and (max-width:600px) {


    .basicsliderserviceslist_carousel-container {
        height: 30vh;
        width: 100vw;
        overflow: hidden;
    }

    .basicsliderserviceslist_carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .basicsliderserviceslist_slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .basicsliderserviceslist_active {
        transform: translateX(-100%);
    }

    .basicsliderserviceslist_passed-images {
        height: 30vh;
        width: 100vw
    }
}

@media only screen and (min-width: 601px) and (max-width:900px) {
    .basicsliderserviceslist_carousel-container {
        height: 50vh;
        width: 100vw;
        overflow: hidden;
    }

    .basicsliderserviceslist_carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .basicsliderserviceslist_slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .basicsliderserviceslist_active {
        transform: translateX(-100%);
    }

    .basicsliderserviceslist_passed-images {
        height: 40vh;
        width: 100vw
    }
}

@media only screen and (min-width: 901px) and (max-width:1200px) {
    .basicsliderserviceslist_carousel-container {
        height: 50vh;
        width: 100vw;
        overflow: hidden;
    }

    .basicsliderserviceslist_carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .basicsliderserviceslist_slide {
        flex: 0 0 100%;
        height: 40%;
        width: 100%;
    }

    .basicsliderserviceslist_active {
        transform: translateX(-100%);
    }

    .basicsliderserviceslist_passed-images {
        height: 40vh;
        width: 100vw
    }

}


@media only screen and (min-width: 1201px) {
    .basicsliderserviceslist_carousel-container {
        height: 50vh;
        width: 100vw;
        overflow: hidden;
    }

    .basicsliderserviceslist_carousel {
        display: flex;
        transition: transform 1s ease;
    }

    .basicsliderserviceslist_slide {
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
    }

    .basicsliderserviceslist_active {
        transform: translateX(-100%);
    }

    .basicsliderserviceslist_passed-images {
        height: 50vh;
        width: 100vw
    }
}