.footer_navWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 160vh;
    color: white;
    background-color: #000000;
}

.footer_navbarlogo {
    height: 20vh;
}

.footer_listOfLinks {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.footer_navLink {
    font-size: medium;
    color: white;

}

.footer_introDiv {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 3%;
    padding-right: 5%; */
}

.footer_h1 {
    font-weight: bolder;
    font-size: xx-large;
    text-align: center;
}

.footer_h3 {
    width: 100vw;
    font-size: large;
    text-align: center;
}

.footer_h6 {
    border-style: double;
    border-bottom: white;

}

.footer_emptySpace {
    width: 5%;

}

.footer_navBtnWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 5%;

}

.footer_socialMediaBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.footer_footer_locationBox {
    display: flex;
    justify-content: center;
    align-items: start;
    justify-content: space-between;
    padding-top: 20px;
    flex-direction: column;

}

.footer_googleMap {
    /* width: fit-content; */
    width: 80vw;
}

.footer_themap {
    width: 80vw;
    height: 100px;
}

.footer_container {
    padding: 20px;
}

@media only screen and (max-width: 390px) {}

@media only screen and (min-width:391px) and (max-width:600px) {

    .footer_h3 {
        width: 100vw;
        font-size: 25px;
        text-align: center;
    }

    .footer_navBtnWrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        padding: 5%;
    }

    .footer_socialMediaBox {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    .footer_smtag {
        height: 80px;
        width: 80px;
    }
}

@media only screen and (min-width:601px) and (max-width:900px) {
    .footer_h3 {
        width: 100vw;
        font-size: 30px;
        text-align: center;
    }

    .footer_smtag {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (min-width:901px) and (max-width:1200px) {

    .footer_h3 {
        width: 100vw;
        font-size: 30px;
        text-align: center;
    }

    .footer_smtag {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (min-width:1201px) {

    .footer_navLink {
        font-size: 20px;
    }

    .footer_h3 {
        width: 100vw;
        font-size: 30px;
        text-align: center;
    }

    .footer_smtag {
        height: 100px;
        width: 100px;
    }
}

/* @media only screen and (max-width: 600px) { */
/* } */