@media only screen and (min-width: 300px) {
    .locationBox {
        display: flex;
        justify-content: center;
        align-items: start;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;

    }

    .contactusP {
        text-align: center;
        margin: 3%;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;

    }

    .googleMap {
        height: 200px;
    }

    .container {
        height: 80vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
}


@media only screen and (min-width: 301px) and (max-width: 600px) {
    .locationBox {
        display: flex;
        justify-content: center;
        align-items: start;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;

    }

    .contactusP {
        text-align: center;
        margin: 3%;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;

    }

    .googleMap {
        height: 200px;
    }

    .container {
        height: 80vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
    .locationBox {
        display: flex;
        justify-content: center;
        align-items: start;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;

    }

    .contactusP {
        text-align: center;
        margin: 3%;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;

    }

    .googleMap {
        height: 200px;
    }

    .container {
        height: 80vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
}

@media only screen and (min-width: 1201px) {
    .locationBox {
        display: flex;
        justify-content: center;
        align-items: start;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;

    }

    .contactusP {
        text-align: center;
        margin: 3%;
    }


    .form {
        display: flex;
        flex-direction: column;
        align-self: center;

    }

    .googleMap {
        height: 200px;
    }

    .container {
        margin: 20px;
        height: 80vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}