@media only screen and (max-width: 300px) {

    .home_fullpage {
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        /* background-color: rgb(255, 240, 222); */
        background-position: center;
        height: 200vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .home_missionStatement {
        width: 85vw;
        height: 45vh;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        padding: 4%;
    }

    .home_missionStatement_bg {
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        border-color: #000000;
        border-style: solid;
        border-right: 0px;
        border-left: 0px;
        height: 60vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_missionStatement_heading {
        font-size: 13px;
        font-style: italic;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-align: center;
        font-weight: 900;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        /* border-bottom: 2px;
        border-style: double; */
    }

    .home_missionStatement_p {
        font-size: 12px;
    }

    .home_missionStatement_p2 {
        font-size: 12px;
        color: rgb(11, 69, 11);
        font-weight: 900;
        text-align: center;
    }

    .homePageBtn {
        background-color: rgb(248, 248, 248);
        color: rgb(11, 69, 11);
        font-weight: 500;
        width: 40vw;
        height: 50px;
        border-radius: 10%;
    }

    .groupPhoto {
        height: 60vh;
        width: 80vw;
    }

    .home_MeetTheTeamCard {
        width: 85vw;
        height: 45vh;
        /* background-color: #fff0de; */
        background-color: rgba(255, 254, 228, 0.658);
        /* border-color: black; */
        /* border-style: solid; */
        border-radius: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5%;
    }

    .home_MeetTheTeamText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_ServiceOptions_BG {
        background-image: url('../MEDIA/BackgroundOption/background8.png');
        background-size: cover;
        border-style: solid;
        border-color: black;
        border-right: 0px;
        border-left: 0px;

    }

    .home_ServiceOptions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 30vh;
        width: 100vw;
        background-color: rgba(255, 254, 228, 0.455);
        /* border-radius: 5%; */
        padding-top: 5%;
    }

    .homePageBtn_p {
        display: none;
    }

    .home_JobOptions {
        height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_JobOptions_title {
        text-align: center;
        font-size: 18px;
    }



}

































































@media only screen and (min-width: 301px) and (max-width:600px) {

    .home_fullpage {
        /* background-color: rgb(255, 240, 222); */
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        background-position: center;
        height: 230vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .home_missionStatement {
        width: 85vw;
        height: 45vh;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        padding: 4%;
    }

    .home_missionStatement_bg {
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        border-color: #000000;
        border-style: solid;
        border-right: 0px;
        border-left: 0px;
        height: 60vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_missionStatement_heading {
        font-size: 20px;
        font-style: italic;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-align: center;
        font-weight: 900;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        /* border-bottom: 2px;
        border-style: double; */
    }

    .home_missionStatement_p {
        font-size: 15px;
    }

    .home_missionStatement_p2 {
        font-size: 14px;
        color: rgb(11, 69, 11);
        font-weight: 900;
        text-align: center;
    }

    .homePageBtn {
        background-color: rgb(248, 248, 248);
        color: rgb(11, 69, 11);
        font-weight: 500;
        width: 40vw;
        height: 5vh;
        border-radius: 10%;
    }

    .groupPhoto {
        height: 260px;
        width: 280px;
    }

    .home_MeetTheTeamCard {
        width: 85vw;
        height: 50vh;
        /* background-color: #fff0de; */
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        /* border-color: black; */
        /* border-style: solid; */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5%;
    }

    .home_MeetTheTeamText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_ServiceOptions_BG {
        background-image: url('../MEDIA/BackgroundOption/background8.png');
        background-size: cover;
        border-style: solid;
        border-color: black;
        border-right: 0px;
        border-left: 0px;

    }

    .home_ServiceOptions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 30vh;
        width: 100vw;
        background-color: rgba(255, 254, 228, 0.455);
        /* border-radius: 5%; */
        padding-top: 5%;
    }

    .homePageBtn_p {
        display: none;
    }

    .home_JobOptions {
        height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_JobOptions_title {
        text-align: center;
        font-size: 16px;
    }



}

































































@media only screen and (min-width: 601px) and (max-width:914px) {

    .home_fullpage {
        /* background-color: rgb(255, 240, 222); */
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        background-position: center;
        height: 210vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .home_missionStatement {
        width: 85vw;
        height: 45vh;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        padding: 4%;
    }

    .home_missionStatement_bg {
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        border-color: #000000;
        border-style: solid;
        border-right: 0px;
        border-left: 0px;
        height: 60vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_missionStatement_heading {
        font-size: 18px;
        font-style: italic;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-align: center;
        font-weight: 900;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 2px;
        border-style: double;
    }

    .home_missionStatement_p {
        font-size: 20px;
    }

    .home_missionStatement_p2 {
        font-size: 18px;
        color: rgb(11, 69, 11);
        font-weight: 700;
        text-align: center;
    }

    .homePageBtn {
        background-color: rgb(248, 248, 248);
        color: rgb(11, 69, 11);
        font-weight: 500;
        width: 40vw;
        height: 70px;
        border-radius: 10%;
    }

    .groupPhoto {
        height: 260px;
        width: 280px;
        padding-left: 1%;
    }

    .home_MeetTheTeamCard {
        width: 95vw;
        height: 45vh;
        /* background-color: #fff0de; */
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        /* border-color: black; */
        /* border-style: solid; */
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        /* padding: 5%; */

    }

    .home_MeetTheTeamText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_ServiceOptions_BG {
        background-image: url('../MEDIA/BackgroundOption/background8.png');
        background-size: cover;
        border-style: solid;
        border-color: black;
        border-right: 0px;
        border-left: 0px;

    }

    .home_ServiceOptions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 30vh;
        width: 100vw;
        background-color: rgba(255, 254, 228, 0.455);
        /* border-radius: 5%; */
        padding-top: 5%;
    }

    .homePageBtn_p {
        display: none;
    }

    .home_JobOptions {
        height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_JobOptions_title {
        text-align: center;
        font-size: 16px;
    }
}

































































@media only screen and (min-width: 915px) and (max-width:1200px) {

    .home_fullpage {
        background-color: rgb(255, 240, 222);
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        /* background-position: unset; */
        background-size: cover;
        background-position: center;
        height: 210vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .home_missionStatement {
        width: 85vw;
        height: 45vh;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        padding: 4%;
    }

    .home_missionStatement_bg {
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        border-color: #000000;
        border-style: solid;
        border-right: 0px;
        border-left: 0px;
        height: 60vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_missionStatement_heading {
        font-size: 30px;
        font-style: italic;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        text-align: center;
        font-weight: 900;
        /* border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 2px;
        border-style: double; */
    }

    .home_missionStatement_p {
        font-size: 25px;
    }

    .home_missionStatement_p2 {
        font-size: 20px;
        color: rgb(11, 69, 11);
        font-weight: 700;
        text-align: center;
    }

    .homePageBtn {
        background-color: rgb(248, 248, 248);
        color: rgb(11, 69, 11);
        width: 40vw;
        height: 70px;
        border-radius: 10%;
        font-size: large;
        font-weight: 1200;
    }

    .groupPhoto {
        height: 360px;
        width: 380px;
        padding-left: 1%;
    }

    .home_MeetTheTeamCard {
        width: 95vw;
        height: 45vh;
        /* background-color: #fff0de; */
        /* border-color: black; */
        /* border-style: solid; */
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
        /* padding: 5%; */

    }

    .home_MeetTheTeamText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_ServiceOptions_BG {
        background-image: url('../MEDIA/BackgroundOption/background8.png');
        background-size: cover;
        border-style: solid;
        border-color: black;
        border-right: 0px;
        border-left: 0px;

    }

    .home_ServiceOptions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 30vh;
        width: 100vw;
        background-color: rgba(255, 254, 228, 0.455);
        /* border-radius: 5%; */
        padding-top: 5%;
    }

    .homePageBtn_p {
        display: none;
    }

    .home_JobOptions {
        height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_JobOptions_title {
        text-align: center;
        font-size: 16px;
    }

}






























































@media only screen and (min-width: 1201px) {

    .home_fullpage {
        /* background-color: rgb(255, 240, 222); */
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        background-position: center;
        height: 400vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

    }

    .home_missionStatement {
        width: 85vw;
        height: 45vh;
        background-color: rgba(255, 254, 228, 0.658);
        /* background-color: rgb(255, 255, 255); */
        border-radius: 5%;
        padding: 4%;
    }

    .home_missionStatement_bg {
        background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg');
        background-size: cover;
        border-color: #000000;
        border-style: solid;
        border-right: 0px;
        border-left: 0px;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .home_missionStatement_heading {
        font-size: 30px;
        font-style: italic;
        text-align: center;
        font-weight: 900;
        font-family: Verdana, Geneva, Tahoma, sans-serif
            /* border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 2px; */
            /* border-style: double; */
    }

    .home_missionStatement_p {
        font-size: 25px;
    }

    .home_missionStatement_p2 {
        font-size: 20px;
        color: rgb(11, 69, 11);
        font-weight: 700;
        text-align: center;
    }

    .homePageBtn {
        background-color: rgb(248, 248, 248);
        color: rgb(11, 69, 11);
        width: 40vw;
        height: 70px;
        border-radius: 10%;
        font-size: large;
        font-weight: 1200;
    }

    .groupPhoto {
        height: 650px;
        width: 650px;
        padding-left: 1%;
    }

    .home_MeetTheTeamCard {
        width: 95vw;
        height: 90vh;
        /* background-color: rgba(255, 254, 228, 0.658); */
        /* background-color: #fff0de; */
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        /* border-color: black; */
        /* border-style: solid; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* gap: 8rem; */
        /* padding: 5%; */

    }

    .home_MeetTheTeamText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_ServiceOptions_BG {
        /* background-image: url('../MEDIA/BackgroundOption/background8.png'); */
        background-size: cover;
        border-style: solid;
        border-color: black;
        border-right: 0px;
        border-left: 0px;

    }


    .homepgbtn_card {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 40vw;
        height: 30vh;
        gap: 5rem;
        font-size: 25px;
        /* background-color: #fff0de; */
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        padding: 1%;
        border-color: #000000;
        border-style: solid;
        border-width: 3px;
        -moz-border-image: url("../MEDIA/BackgroundOption/background2.jpg");
    }

    .home_ServiceOptions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 100vh;
        width: 100vw;
        /* background-color: rgba(255, 254, 228, 0.455); */
        /* border-radius: 5%; */
        padding-top: 5%;
    }

    .home_JobOptions {
        height: 30vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_JobOptions_title {
        text-align: center;
        font-size: 36px;
    }

}