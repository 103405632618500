@media only screen and (min-width: 300px) {
    .fullpage {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .decaturLocation {
        height: 185vh;
        width: 100vw;
        /* background-color: #61f554; */
        background-color: #abca9b;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .storelocation {
        text-align: center;
    }

    .locationimg {
        height: 50vh;
        width: 100vw;
    }

    .locationimgriverdale {
        height: 50vh;
        width: 100vw;
    }

    .contactusHeading {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .locationBox {
        height: 75vh;
        width: 95vw;
        border-radius: 10%;
        background-color: bisque;
        text-align: center;
        padding-bottom: 5%;

    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 75vw;
        height: 25vh;

    }

    .form-control {
        width: 75vw;
    }

    .googleMap {
        height: 20vh;
        width: 95vw;
        padding-left: 4%;
    }

    .locationBox {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;
        font-size: 20px;

    }

    .container {
        width: 100vw;
    }
}

@media only screen and (min-width: 301px) and (max-width:600px) {

    .fullpage {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .decaturLocation {
        height: 195vh;
        width: 100vw;
        /* background-color: #61f554; */
        background-color: #abca9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .storelocation {
        text-align: center;
    }

    .locationimg {
        height: 50vh;
        width: 100vw;
    }

    .locationimgriverdale {
        height: 50vh;
        width: 100vw;
    }

    .contactusHeading {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .locationBox {
        height: 75vh;
        width: 95vw;
        border-radius: 10%;
        background-color: bisque;
        text-align: center;
        padding-bottom: 5%;


    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 75vw;
        height: 25vh;

    }

    .form-control {
        width: 75vw;
    }

    .googleMap {
        height: 20vh;
        width: 95vw;
        padding-left: 4%;
    }

    .locationBox {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;
        font-size: 20px;

    }

    .container {
        width: 100vw;
    }

}

@media only screen and (min-width: 601px) and (max-width:900px) {

    .fullpage {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .decaturLocation {
        height: 185vh;
        width: 100vw;
        /* background-color: #61f554; */
        background-color: #abca9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .storelocation {
        text-align: center;
    }

    .locationimg {
        height: 70vh;
        width: 65vw;
    }

    .locationimgriverdale {
        height: 70vh;
        width: 85vw;
    }

    .contactusHeading {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .locationBox {
        height: 65vh;
        width: 95vw;
        border-radius: 10%;
        background-color: bisque;
        text-align: center;
        padding-bottom: 5%;

    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 75vw;
        height: 25vh;

    }

    .form-control {
        width: 75vw;
    }

    .googleMap {
        height: 20vh;
        width: 95vw;
        padding-left: 4%;
    }

    .locationBox {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;
        font-size: 20px;

    }

    .container {
        width: 100vw;
    }

}

@media only screen and (min-width: 901px) and (max-width:1200px) {

    .fullpage {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .decaturLocation {
        height: 185vh;
        width: 100vw;
        /* background-color: #61f554; */
        background-color: #abca9b;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .storelocation {
        text-align: center;
    }

    .locationimg {
        height: 80vh;
        width: 65vw;
    }

    .locationimgriverdale {
        height: 70vh;
        width: 75vw;
    }

    .contactusHeading {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .locationBox {
        height: 75vh;
        width: 95vw;
        border-radius: 10%;
        background-color: bisque;
        text-align: center;
        padding-bottom: 5%;

    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 75vw;
        height: 25vh;

    }

    .form-control {
        width: 75vw;
    }

    .googleMap {
        height: 20vh;
        width: 95vw;
        padding-left: 4%;
    }

    .locationBox {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;
        font-size: 20px;

    }

    .container {
        width: 100vw;
    }


}

@media only screen and (min-width: 1201px) {
    .fullpage {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .decaturLocation {
        height: 200vh;
        width: 100vw;
        /* background-color: #61f554; */
        background-color: #abca9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .storelocation {
        text-align: center;
    }

    .locationimg {
        height: 80vh;
        width: 65vw;
    }

    .locationimgriverdale {
        height: 90vh;
        width: 65vw;
    }

    .contactusHeading {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .locationBox {
        height: 65vh;
        width: 95vw;
        border-radius: 10%;
        background-color: bisque;
        text-align: center;
        padding-bottom: 5%;

    }

    .form {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 75vw;
        height: 25vh;

    }

    .form-control {
        width: 75vw;
    }

    .googleMap {
        height: 20vh;
        width: 95vw;
        padding-left: 3%;
    }

    .locationBox {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: column;
        font-size: 20px;

    }

    .googleMap {
        height: 200px;
        width: 100vw;
    }

    .container {
        width: 100vw;
    }

    .button {
        font-size: 36px;
    }

}