.navbar_nav {
    background-image: linear-gradient(to right, #027344e1, #1fb450, #23d75f, #61f554);
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2%;
}

.navbar_navbarlogo {
    height: 10vh;
    width: 16vw;
}

/* .navbar_nav a {
    color: inherit;
    width: 100vw;
} */

.navbar_nav-rightBracket {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90vw;
    height: 10vh;
    text-align: center;
}

.navbar_dropdown {
    float: left;
    overflow: hidden;
}

.navbar_btn {
    /* background-color: blue; */
    font-weight: 700;
    /* color: whitesmoke; */
    color: black;
}

.navbar_dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.navbar_dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: right;
    /* z-index: 10; */

}

.navbar_dropdown-content a:hover {
    background-color: #ddd;
}



@media only screen and (max-width: 390px) {

    .navbar_btn {
        height: 7vh;
        width: 18vw;
        font-size: 11px;
    }

    /* .navbar_homeLink {} */

}

@media only screen and (min-width:391px) and (max-width:600px) {

    .navbar_btn {
        height: 7vh;
        width: 90%;
        font-size: 14px;
    }

    .navbar_homeLink {
        font-size: 14px;
    }
}

@media only screen and (min-width:601px) and (max-width:900px) {

    .navbar_btn {
        height: 7vh;
        width: 90%;
        font-size: 16px;
    }

    .navbar_navbarlogo {
        height: 10vh;
        width: 16vw;
    }

    .navbar_homeLink {
        font-size: 16px;
    }
}

@media only screen and (min-width:901px) and (max-width:1200px) {

    .navbar_btn {
        height: 7vh;
        width: 90%;
        font-size: 20px;
    }

    .navbar_navbarlogo {
        height: 10vh;
        width: 16vw;
    }

    .navbar_homeLink {
        font-size: 20px;
    }
}

@media only screen and (min-width:1201px) {

    .navbar_btn {
        height: 7vh;
        width: 100%;
        font-size: 20px;
    }

    .navbar_navbarlogo {
        height: 10vh;
        width: 12vw;
    }

    .navbar_homeLink {
        font-size: 25px;
    }

    .navbar_btn {
        font-size: 25px;
        /* background-color: blue; */
        font-weight: 700;
        /* color: whitesmoke; */
        color: black;
    }


}

/* .navbar_taxPassSignIn {
    font-size: 45px;
    width: 1vw;
} */