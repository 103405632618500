@media only screen and (max-width: 300px) {
    .services_fullPage {
        width: 100vw;
        height: 500vh;
        /* background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg'); */
        background-color: white;
        background-position: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }


    .services_smallcard {
        height: 36vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
    }

    .smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
    }

    .smallcard_heading {
        font-size: 25px;
        width: 60vw;
    }

    .ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .smallcard_block {
        font-size: 16px;
    }

    .super_smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
        justify-content: space-between;
    }

    .super_smallcard_heading {
        font-size: 23px;
    }

    .super_ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .extras_section {
        height: 90vh;
        width: 100vw;
        background-color: rgba(255, 251, 228, 0.699);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .services_bigcard {
        height: 25vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;

    }

    .bigcard_heading {
        font-size: 20px;
    }

    .bigcard_p {
        font-size: 14px;
    }

    .partneredBanks {
        font-size: 25px;
    }

}





@media only screen and (min-width: 301px) and (max-width:600px) {
    .services_fullPage {
        width: 100vw;
        height: 650vh;
        background-color: white;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }


    .services_smallcard {
        height: 45vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .smallcard_ribbon {
        height: 18vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
        justify-content: space-between;
    }

    .smallcard_heading {
        width: 60vw;
        font-size: 30px;
    }

    .ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .smallcard_block {
        font-size: 22px;
    }

    .super_smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        /* align-items: flex-start; */
        align-items: center;
        justify-content: center;
        justify-content: space-around;
    }

    .super_smallcard_heading {
        width: 60vw;
        font-size: 23px;
    }

    .super_ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .extras_section {
        height: 150vh;
        width: 100vw;
        background-color: rgba(255, 251, 228, 0.699);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .services_bigcard {
        height: 25vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;

    }

    .bigcard_heading {
        font-size: 26px;
    }

    .bigcard_p {
        font-size: 20px;
    }

    .partneredBanks {
        font-size: 25px;
    }

}






@media only screen and (min-width: 601px) and (max-width:900px) {
    .services_fullPage {
        width: 100vw;
        height: 550vh;
        /* background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg'); */
        background-color: white;
        background-position: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }


    .services_smallcard {
        height: 40vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
    }

    .smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
    }

    .smallcard_heading {
        width: 60vw;
        font-size: 40px;
    }

    .ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .smallcard_block {
        font-size: 26px;
        margin: 2%;

    }

    .super_smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
        justify-content: space-between;
    }

    .super_smallcard_heading {
        width: 60vw;
        font-size: 36px;
        margin: 2%;

    }

    .super_ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .extras_section {
        height: 90vh;
        width: 100vw;
        background-color: rgba(255, 251, 228, 0.699);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .services_bigcard {
        height: 25vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;

    }

    .bigcard_heading {
        font-size: 30px;
    }

    .bigcard_p {
        font-size: 22px;
        margin: 2%;
    }

}






@media only screen and (min-width: 901px) and (max-width:1200px) {
    .services_fullPage {
        width: 100vw;
        height: 550vh;
        /* background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg'); */
        background-color: white;
        background-position: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }


    .services_smallcard {
        height: 40vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
    }

    .smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
    }

    .smallcard_heading {
        width: 60vw;
        font-size: 40px;
    }

    .ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .smallcard_block {
        font-size: 26px;
        margin: 2%;

    }

    .super_smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
        justify-content: space-between;
    }

    .super_smallcard_heading {
        width: 60vw;
        font-size: 36px;
        margin: 2%;

    }

    .super_ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .extras_section {
        height: 90vh;
        width: 100vw;
        background-color: rgba(255, 251, 228, 0.699);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .services_bigcard {
        height: 25vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;

    }

    .bigcard_heading {
        font-size: 30px;
    }

    .bigcard_p {
        font-size: 22px;
        margin: 2%;
    }

}






@media only screen and (min-width: 1201px) {
    .services_fullPage {
        width: 100vw;
        height: 550vh;
        /* background-image: url('../MEDIA/BackgroundOption/Dark\ Blue\ and\ Green\ Bold\ Tech\ Software\ Development\ Business\ Website\ \(2\).jpg'); */
        background-color: white;
        background-position: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }


    .services_smallcard {
        height: 40vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .smallcard_ribbon {
        height: 25vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
    }

    .smallcard_heading {
        width: 80vw;
        font-size: 40px;
    }

    .ribbon_image {
        height: 25vh;
        width: 20vw;
    }

    .smallcard_block {
        font-size: 30px;
        margin: 2%;

    }

    .super_smallcard_ribbon {
        height: 15vh;
        width: 97vw;
        background-color: red;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;
        justify-content: space-between;
    }

    .super_smallcard_heading {
        width: 60vw;
        font-size: 36px;
        margin: 2%;

    }

    .super_ribbon_image {
        height: 15vh;
        width: 40vw;
    }

    .extras_section {
        height: 90vh;
        width: 100vw;
        background-color: rgba(255, 251, 228, 0.699);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .services_bigcard {
        height: 25vh;
        width: 97vw;
        background-color: rgba(255, 254, 228, 0.658);
        border-radius: 5%;

    }

    .bigcard_heading {
        font-size: 30px;
    }

    .bigcard_p {
        font-size: 22px;
        margin: 2%;
    }

}